import { HttpClient } from '@angular/common/http';
import { Injectable, effect, inject, signal } from '@angular/core';
import { cloneDeep, get, isEmpty, set } from 'lodash';
import { Observable, forkJoin, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { ADMIN_OVERRIDE_CODE, API_SERVICE_URL, APPROVER_EDIT_CODE, COST_JOB_LEVEL_ID, PROCESSING_CODE, REJECTED_CODE, REQUESTOR_EDIT_CODE, REQUESTOR_WITHDRAW_CODE, REVISION_CODE, STORE_JOB_LEVEL_ID, SUBMITTED_CODE } from 'src/app/GlobalConstants';
import { Employee } from 'src/app/admin/employee/employee.model';
import { LoginService } from 'src/app/login/login.service';
import { DepartmentIdsParam, DepartmentInfo, DepartmentUser, DepartmentUserRequest } from 'src/app/shared/department-user/department-user.model';
import { DepartmentUserService } from 'src/app/shared/department-user/department-user.service';
import { EmployeeCardModel } from 'src/app/shared/includes/employee-card/employee-card.model';
import { ApiReloadStatus } from 'src/app/shared/includes/page-header/page-header.model';
import { PageHeaderService } from 'src/app/shared/includes/page-header/page-header.service';
import { StringCollection } from 'src/app/shared/shared.model';
import { AttachmentsService } from '../shared/attachments/attachments.service';
import { JobEmployeeView, JobFlowLog, JobSummary } from '../shared/job-summary/job-summary.model';
import { JobSummaryService } from '../shared/job-summary/job-summary.service';
import { UserInformation, constructMultiUserInformation, constructUserInformation } from '../shared/user-information.model';
import { JobFlowParams, LogItem } from './log-details/log-details.model';
import { LogDetailsService } from './log-details/log-details.service';
import {
  ApproverEmployee, ApproversInformation,
  CurrentApproverRequest,
  MaterialRequisitionData,
  RequestDetail, RequestDetailResponse,
  RequestDetailTime
} from './request-detail.model';

const MATERIAL_REQUEST_URL = `${API_SERVICE_URL}/materialrequisition`;

@Injectable({
  providedIn: 'root',
})
export class RequestDetailService {
  private http = inject(HttpClient);
  private departmentService = inject(DepartmentUserService);
  private jobService = inject(JobSummaryService);
  private pageService = inject(PageHeaderService);
  private worklogService = inject(LogDetailsService);
  private attachmentsService = inject(AttachmentsService);
  private loginService: LoginService = inject(LoginService);

  private profileIdCopy = signal<number>(0);
  private approversInformation: ApproversInformation = {};
  private departmentIdsParam: DepartmentIdsParam = {};
  private materialRequisitionData: MaterialRequisitionData = {};

  private readonly profileData = this.loginService.profileData.asReadonly();

  private readonly isLogoutTriggered = this.pageService.logoutTriggered.asReadonly();

  constructor() {
    effect(() => {
      if (this.profileData()?.id !== this.profileIdCopy()) {
        this.profileIdCopy.set(this.profileData()?.id ?? 0);
        this.resetCache();
      }
      if (this.isLogoutTriggered()) {
        this.resetCache();
      }
    })
  }

  public getMaterialRequisitionData(serialNumber: string): RequestDetailTime {
    return this.materialRequisitionData[serialNumber.toLowerCase()];
  }

  public setMaterialRequisitionData(serialNumber: string, requestData: RequestDetailTime): void {
    this.materialRequisitionData[serialNumber.toLowerCase()] = requestData;
  }

  getMaterialRequisitionDetails = (serialNumber: string): Observable<RequestDetailResponse> => {
    if (!serialNumber) {
      return this.pageService.errorHelper('getMaterialRequisitionDetails: missing the required params.');
    }

    const existingValue: RequestDetailTime = this.getMaterialRequisitionData(serialNumber);
    const reloadStatus = get(this.pageService.apiReloadStatus$.value, 'requestDetail');
    if (!reloadStatus && existingValue) {
      return this.getCombinedData(existingValue);
    }

    const requestUrl = `${MATERIAL_REQUEST_URL}/${serialNumber}`;
    return this.http.get<RequestDetail>(requestUrl)
      .pipe(
        switchMap((detailsResponse: RequestDetail) => {
          const requestData = {
            requestAsOf: new Date(),
            details: detailsResponse
          };
          this.setMaterialRequisitionData(serialNumber, requestData);
          return this.getCombinedData(this.getMaterialRequisitionData(serialNumber));
        }),
      );
  }

  getDepartmentIdsParam(serialNumber: string): DepartmentInfo[] {
    return get(this.departmentIdsParam, serialNumber.toLowerCase(), []);
  }

  private getCombinedData = (detailsWithTime: RequestDetailTime): Observable<RequestDetailResponse> => {
    const detailsResponse = detailsWithTime.details;
    const {
      id,
      companyId,
      jobId,
      storeDepartmentId,
      costDepartmentId,
      serialNumber,
    } = detailsResponse;

    const departParam: DepartmentUserRequest = {
      companyId,
      departmentIds: [
        { reference: 'store', id: storeDepartmentId },
        { reference: 'cost', id: costDepartmentId },
      ],
    };

    set(this.departmentIdsParam, serialNumber.toLowerCase(), departParam.departmentIds);

    const apiObservables = [
      this.jobService.getJobDetail({ companyId, jobId }),
      this.departmentService.getDepartmentUsers(departParam),
      this.worklogService.getLogDetails(id),
      this.attachmentsService.getAttachments(id),
    ];

    return forkJoin(apiObservables)
      .pipe(
        map(([jobDetail, departmentUsers, workLog, attachments]) => {
          set(detailsResponse, 'attachments', attachments);
          const clonedWorkLog = cloneDeep(workLog);
          const jobFlow = this.setInitialLogs(detailsResponse, jobDetail as JobSummary, clonedWorkLog as LogItem[], departmentUsers as DepartmentUser[]);
          this.worklogService.setJobFlow(detailsResponse.serialNumber, jobFlow);
          const consolidatedResponse: RequestDetailResponse = {
            detailsResponse: detailsWithTime,
            jobDetail: jobDetail as JobSummary,
            departmentUsers: departmentUsers as DepartmentUser[],
            workLog: clonedWorkLog as LogItem[],
            departParam,
          };
          this.updateReloadStatus({ requestDetail: false, approverInfo: true });
          return consolidatedResponse;
        })
      );
  }

  private isRequestOnError(workLog: LogItem[], status: number): boolean {
    return workLog.length > 0 && workLog[0].requestStatus === status;
  }

  public setInitialLogs(detailsResponse: JobFlowParams, jobInfo: JobSummary, workLog: LogItem[] = [], departmentUsers: DepartmentUser[] = []): JobFlowLog[] {
    // Requestor
    const logs: JobFlowLog[] = [];
    const requestorLog = workLog.find(item => item.jobLevelId === 0 && item.requestStatus === SUBMITTED_CODE);
    let userInfo: UserInformation;
    if (detailsResponse.serialNumber) {
      userInfo = this.getUserInfoFromLog(requestorLog as LogItem);
    } else {
      const profileInfo = this.profileData()!;
      userInfo = {
        title: profileInfo.name,
        description: 'Requestor',
        userDetails: [{
          employeeName: profileInfo.name,
          employeeEmail: profileInfo.email,
          employeeId: profileInfo.id,
          employeeCode: profileInfo.code,
          departmentName: profileInfo.departmentName,
          employeeStatus: 'Active',
        }],
      };
    }
    logs.push(this.insertIntoLogs(0, 'Requestor', 'edit_note', userInfo));
    const clonedLogs = cloneDeep(logs);
    if (this.isRequestOnError(workLog, ADMIN_OVERRIDE_CODE)) {
      logs[0] = this.updateLog('completed', false, clonedLogs[0]);
      logs.push(this.insertIntoLogs(0, workLog[0].employeeName, 'thumb_down_alt', this.getUserInfoFromLog(workLog[0]), false, true));
    } else if (this.isRequestOnError(workLog, REJECTED_CODE)) {
      logs[0] = this.updateLog('completed', false, clonedLogs[0]);
      logs.push(this.insertIntoLogs(workLog[0].jobLevelId, workLog[0].jobLevelCategory as string, 'thumb_down_alt', this.getUserInfoFromLog(workLog[0]), false, true));
    } else if (this.isRequestOnError(workLog, REQUESTOR_WITHDRAW_CODE)) {
      logs[0] = this.updateLog('completed', false, clonedLogs[0]);
      logs.push(this.insertIntoLogs(workLog[0].jobLevelId, 'Requestor', 'person_off', this.getUserInfoFromLog(workLog[0]), false, true));
    } else {
      const updateIndex = workLog.findIndex(item => REQUESTOR_EDIT_CODE === item.requestStatus);
      if (updateIndex >= 0) {
        workLog.splice(updateIndex + 1);
      }
      const revisionIndex = workLog.findIndex(item => REVISION_CODE === item.requestStatus);
      if (revisionIndex === 0) {
        logs[0] = this.updateLog('revise', true, clonedLogs[0]);
      } else if (revisionIndex > 0) {
        workLog.splice(revisionIndex, workLog.length - revisionIndex);
      }
      // Approvers
      jobInfo.jobLevels.forEach((jobLevel, index) => {
        let employees = jobLevel.jobEmployeesView as JobEmployeeView[];
        if (index === 0) {
          employees = employees.filter((employee) => employee.employeeId === detailsResponse.approverId);
        }
        if (index === 0 && !employees.length) {
          const initialApprover = workLog.find((log: LogItem) => log.jobLevelId === jobLevel.jobLevelId) as LogItem;
          userInfo = this.getUserInfoFromLog(initialApprover);
        } else {
          userInfo = {
            title: jobLevel.jobLevelCategory,
            description: '',
            userDetails: constructMultiUserInformation(employees)
          };
        }
        logs.push(this.insertIntoLogs(jobLevel.jobLevelId as number, jobLevel.jobLevelCategory, 'group', userInfo));
      });

      // Cost Team
      if (jobInfo.costCode) {
        userInfo = this.getDepartmentUserInfo(departmentUsers, detailsResponse.costDepartmentId as number);
        logs.push(this.insertIntoLogs(COST_JOB_LEVEL_ID, 'Cost', 'savings', userInfo));
      }

      // Store Team
      userInfo = this.getDepartmentUserInfo(departmentUsers, detailsResponse.storeDepartmentId as number);
      logs.push(this.insertIntoLogs(STORE_JOB_LEVEL_ID, 'Store', 'store', userInfo));
      return this.setWorkLogStatus(logs, workLog);
    }
    return logs;
  }

  private setWorkLogStatus(jobLogs: JobFlowLog[], workLog: LogItem[]): JobFlowLog[] {
    let isProgress: boolean = false;
    return jobLogs.map((jobLog: JobFlowLog) => {
      if (!isProgress && !jobLog.isProgress) {
        if (workLog.some(item => item.jobLevelId === jobLog.id && ![PROCESSING_CODE, APPROVER_EDIT_CODE].includes(item.requestStatus as number))) {
          jobLog = this.updateLog('completed', false, { ...jobLog });
        } else {
          jobLog = this.updateLog('progress', true, { ...jobLog });
          isProgress = true;
        }
      } else {
        isProgress = true;
      }
      return jobLog;
    });
  }

  private insertIntoLogs(
    id: number, title: string, icon: string, userInformation: UserInformation, isProgress: boolean = false, isError: boolean = false
  ): JobFlowLog {
    return {
      id, title, icon, isProgress, userInformation,
      style: isError ? 'jf-error' : isProgress ? 'jf-progress' : 'jf-pending',
    };
  }

  private updateLog(status: string, progress: boolean = false, log: JobFlowLog): JobFlowLog {
    const icon: StringCollection = { completed: 'how_to_reg', revise: 'priority_high', error: 'thumb_down_alt', progress: 'edit_note' };
    const style: StringCollection = { completed: 'jf-completed', revise: 'jf-error', error: 'jf-error', progress: 'jf-progress' };

    return {
      ...log,
      icon: icon[status],
      style: style[status],
      isProgress: progress,
    };
  }

  private getUserInfoFromLog(logItem: LogItem): UserInformation {
    if (isEmpty(logItem)) {
      return {
        title: '',
        description: '',
        userDetails: [],
      };
    }
    return {
      title: logItem.employeeName,
      description: logItem.jobLevelCategory as string,
      userDetails: constructUserInformation(logItem, { showDescription: false })?.userDetails,
    };
  }

  private getDepartmentUserInfo(departmentUsers: DepartmentUser[], departmentId: number): UserInformation {
    const deptInfo = departmentUsers.find(dept => dept.id === departmentId);
    if (!deptInfo) {
      return {
        title: '',
        description: '',
        userDetails: [],
      };
    }
    const userDetails: EmployeeCardModel[] = [];
    deptInfo.employees.forEach((user: Employee) => {
      userDetails.push({
        employeeName: user.name,
        employeeEmail: user.email,
        employeeId: user.id ?? 0,
        employeeCode: user.code,
        departmentName: deptInfo.name,
        employeeStatus: user.status,
      });
    });
    return {
      title: deptInfo.name,
      description: deptInfo.description,
      userDetails,
    };
  }

  getApproversList(params: CurrentApproverRequest): Observable<ApproverEmployee[]> {
    const {
      requestId,
      jobLevelId,
    } = params;
    const existingValue = get(this.approversInformation, `${requestId}-${jobLevelId}`);
    const reloadStatus = get(this.pageService.apiReloadStatus$.value, 'approverInfo');

    if (!reloadStatus && existingValue) {
      return of(existingValue);
    }
    const currentApproversUrl = MATERIAL_REQUEST_URL + `/approvers?reqId=${requestId}&jobLevelId=${jobLevelId}`;
    return this.http.get<ApproverEmployee[]>(currentApproversUrl)
      .pipe(
        tap((approvers: ApproverEmployee[]) => {
          set(this.approversInformation, `${requestId}-${jobLevelId}`, approvers);
        })
      );
  }

  public updateReloadStatus = (param: ApiReloadStatus) => this.pageService.setApiReloadStatus(param)

  private resetCache() {
    this.approversInformation = {};
    this.departmentIdsParam = {};
    this.materialRequisitionData = {};
  }
}
