import { Location, TitleCasePipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { APPLICATION_DESCRIPTION, APPLICATION_TITLE, PACKAGE_VERSION } from 'src/app/GlobalConstants';
import {
  APP_URL, ASSET_REPORTS_PAGE_URL, CHANGE_PAGE_URL, COMPANY_PAGE_URL,
  ITEM_REPORTS_PAGE_URL, JOB_REPORTS_PAGE_URL, LOGIN_PAGE_URL, MATERIAL_REPORTS_PAGE_URL,
  METRICS_PAGE_URL, MY_REQUEST_PAGE_URL, MY_TASK_PAGE_URL, PROFILE_PAGE_URL, REQUEST_DETAIL_DIRECT_URL,
  REQUEST_PENDING_PAGE_URL
} from 'src/app/RouteConstants';
import { NewRequestDetailComponent } from 'src/app/client/create-request/new-request-detail/new-request-detail.component';
import { LoginService } from 'src/app/login/login.service';
import { MaterialModule } from 'src/app/material.module';
import { stringMerge } from 'src/app/shared/utilities/helper';
import { AuthDirective } from '../../utilities/auth/auth.directive';
import { PageHeaderService } from '../page-header/page-header.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss',
  imports: [
    MaterialModule,
    TitleCasePipe,
    AuthDirective,
    RouterLink,
    RouterLinkActive
  ]
})
export class NavbarComponent {
  private dialog = inject(MatDialog);
  private pageService = inject(PageHeaderService);
  private loginService: LoginService = inject(LoginService);
  private router = inject(Router);
  public location = inject(Location);

  public readonly profileData = this.loginService.profileData.asReadonly();
  public readonly userData = this.loginService.loginData.asReadonly();

  readonly applicationTitle: string = APPLICATION_TITLE;
  readonly applicationDescription: string = APPLICATION_DESCRIPTION;
  public readonly version: string = PACKAGE_VERSION;
  public readonly homePageUrl: string = APP_URL;
  public readonly myTaskPageUrl: string = MY_TASK_PAGE_URL;
  public readonly myRequestPageUrl: string = MY_REQUEST_PAGE_URL;
  public readonly jobReportPageUrl: string = JOB_REPORTS_PAGE_URL;
  public readonly materialReportPageUrl: string = MATERIAL_REPORTS_PAGE_URL;
  public readonly reportPendingPageUrl: string = REQUEST_PENDING_PAGE_URL;
  public readonly assetReportPageUrl: string = ASSET_REPORTS_PAGE_URL;
  public readonly itemReportPageUrl: string = ITEM_REPORTS_PAGE_URL;
  public readonly metricsPageUrl: string = METRICS_PAGE_URL;
  public readonly companyPageUrl: string = COMPANY_PAGE_URL;
  public readonly profilePageUrl: string = PROFILE_PAGE_URL;
  public readonly changePageUrl: string = CHANGE_PAGE_URL;
  public readonly loginPageUrl: string = LOGIN_PAGE_URL;

  searchHandler = (event: KeyboardEvent) => {
    const target = event.target as HTMLInputElement;
    const inputValue = target?.value.trim();
    if (inputValue !== '' && event.key === 'Enter') {
      this.pageService.gLog({
        type: 'event',
        action: 'Search',
        category: 'Nav Bar',
        label: this.userData()?.role || '',
        value: this.profileData()?.id,
      });
      target.value = '';
      const requestDetailsUrl = stringMerge(REQUEST_DETAIL_DIRECT_URL, inputValue);
      this.router.navigateByUrl(requestDetailsUrl);
      target.value = '';
    }
  }

  initiateLogout(event: Event) {
    event.preventDefault();
    this.pageService.gLog({
      type: 'event',
      action: 'click',
      category: 'Logout',
      label: this.userData()?.role || '',
      value: this.profileData()?.id,
    });
    this.loginService.logout();
    this.router.navigateByUrl(LOGIN_PAGE_URL);
  }

  showNewRequest() {
    this.dialog.open(NewRequestDetailComponent, {
      width: '400px',
      minWidth: '300px'
    });
  }
}
