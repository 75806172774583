import { EmployeeCardModel } from 'src/app/shared/includes/employee-card/employee-card.model';
import { LogItem } from '../request-details/log-details/log-details.model';
import { JobEmployeeView } from './job-summary/job-summary.model';

export interface UserInformation {
  title: string;
  description?: string;
  userDetails: EmployeeCardModel[];
}

interface UserOptions {
  showDescription: boolean;
}

const getUserDetail = (data: EmployeeCardModel): EmployeeCardModel => {
  const {
    employeeName = '',
    employeeEmail = '',
    employeeId = 0,
    departmentName = '',
    employeeCode = '',
  } = data;

  return {
    employeeEmail,
    employeeId,
    employeeCode,
    employeeName,
    userName: employeeName,
    departmentName,
    employeeStatus: data.employeeStatus,
  };
};

export const constructUserInformation = (data: LogItem, options: UserOptions): UserInformation => {
  const { jobLevelCategory = '', employeeName } = data;
  const { showDescription } = options;
  const description = showDescription ? jobLevelCategory : '';
  return {
    title: employeeName,
    description,
    userDetails: [getUserDetail(data)],
  };
};

export const constructMultiUserInformation = (data: JobEmployeeView[]): EmployeeCardModel[] => {
  const userDetails: EmployeeCardModel[] = [];
  data.forEach((item) => {
    if (!item.employeeStatus || item.employeeStatus === 'Active') {
      userDetails.push(getUserDetail(item));
    }
  });
  return userDetails;
};
