import { HttpClient } from '@angular/common/http';
import { Injectable, effect, inject } from '@angular/core';
import { get, isEmpty, set } from 'lodash';
import { Observable, map, of } from 'rxjs';
import { API_SERVICE_URL } from 'src/app/GlobalConstants';
import { ApiReloadStatus } from 'src/app/shared/includes/page-header/page-header.model';
import { PageHeaderService } from 'src/app/shared/includes/page-header/page-header.service';
import { ItemDescription, ItemReport, ItemReportTime } from './item-report.model';
@Injectable({
  providedIn: 'root'
})
export class ItemReportService {
  private http = inject(HttpClient);
  private pageService = inject(PageHeaderService);

  private serviceUrl = `${API_SERVICE_URL}/createrequest`;
  private itemDataset: ItemReport = {};

  private readonly isLogoutTriggered = this.pageService.logoutTriggered.asReadonly();
  constructor() {
    effect(() => {
      if (this.isLogoutTriggered()) {
        this.resetCache();
      }
    });
  }

  getDescription = (companyId: number): Observable<ItemReportTime> => {
    if (!companyId) {
      return this.pageService.errorHelper('getJobDetail: missing the required params.');
    }
    const reloadStatus: boolean = get(this.pageService.apiReloadStatus$.value, 'itemReport', false);
    const existingValue = get(this.itemDataset, `${companyId}`);
    if (!reloadStatus && !isEmpty(existingValue)) {
      return of(existingValue);
    }

    const getDescriptionUrl = `${this.serviceUrl}/company/${companyId}/description`;
    return this.http.get<ItemDescription[]>(getDescriptionUrl)
      .pipe(
        map((items: ItemDescription[]) => {
          const revisedResponse: ItemReportTime = {
            requestAsOf: new Date(),
            itemReport: items
          };
          set(this.itemDataset, `${companyId}`, revisedResponse);
          this.updateReloadStatus({ itemReport: false });
          return revisedResponse;
        }),
      );
  }

  public updateReloadStatus = (param: ApiReloadStatus) => this.pageService.setApiReloadStatus(param)

  private resetCache() {
    this.itemDataset = {};
  }
}
