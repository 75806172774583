import { Component, effect, inject, signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { get, isEmpty } from 'lodash';
import { finalize, first } from 'rxjs';
import { SNACK_BAR_DISMISS_BUTTON, SNACK_BAR_ERROR_DURATION } from 'src/app/GlobalConstants';
import { GENERIC_API_ERROR } from 'src/app/MessageConstants';
import { CREATE_REQUEST_URL } from 'src/app/RouteConstants';
import { MaterialModule } from 'src/app/material.module';
import { CompanyListComponent } from 'src/app/shared/includes/company-list/company-list.component';
import { EmployeeCardComponent } from 'src/app/shared/includes/employee-card/employee-card.component';
import { JobListComponent } from 'src/app/shared/includes/job-list/job-list.component';
import { PrintErrorComponent } from 'src/app/shared/includes/print-error/print-error.component';
import { ButtonComponent } from 'src/app/shared/library/button/button.component';
import { Button } from 'src/app/shared/library/button/button.model';
import { CompanyControlProperties } from 'src/app/shared/shared.model';
import { JobEmployeeView, JobLevel, JobSummary } from '../../shared/job-summary/job-summary.model';
import { CreateRequestService } from '../create-request.service';

@Component({
  selector: 'app-new-request-detail',
  templateUrl: './new-request-detail.component.html',
  styleUrl: './new-request-detail.component.scss',
  imports: [
    ButtonComponent,
    CompanyListComponent,
    EmployeeCardComponent,
    JobListComponent,
    MaterialModule,
    PrintErrorComponent,
    ReactiveFormsModule,
  ]
})
export class NewRequestDetailComponent {
  private createService = inject(CreateRequestService);
  private router = inject(Router);
  private dialog = inject(MatDialog);
  private snackBar = inject(MatSnackBar);

  private readonly requestInformation = this.createService.requestInformation.asReadonly();

  form: FormGroup = new FormGroup({
    companyId: new FormControl(0, Validators.required),
    jobInfo: new FormControl(null),
    approverInfo: new FormControl(null, Validators.required),
  });
  public levelOneJobEmployee = signal<JobEmployeeView[]>([]);
  public isSelectedJobApproverLoading = signal<boolean>(false);

  public setSelectedCompany = (companyId: number) => {
    this.form.reset();
    this.form.setValue({
      companyId,
      jobInfo: null,
      approverInfo: null,
    });
  }

  public companyControl = signal<CompanyControlProperties>({
    handler: this.setSelectedCompany,
    controlName: this.form.get('companyId'),
    triggerValidation: false,
  });

  public jobShowError = signal<boolean>(false);
  public jobDisabled = signal<boolean>(false);

  constructor() {
    effect(() => {
      if (this.requestInformation()) {
        const material = this.requestInformation();
        if (!isEmpty(material?.companyId?.toString())) {
          this.companyControl.update(config => ({ ...config, defaultValue: material.companyId }));
          this.form.setValue({
            companyId: material.companyId as number,
            jobInfo: material.jobInfo as JobSummary,
            approverInfo: material.approverInfo as JobEmployeeView,
          });
        } else {
          this.form.setValue({
            companyId: 0,
            jobInfo: null,
            approverInfo: null,
          });
        }
      }
    });
  }

  private getSelectedJobApprover(jobId: number) {
    this.isSelectedJobApproverLoading.set(true);
    this.levelOneJobEmployee.set([]);
    this.createService.getSelectedJobApprover(this.form.get('companyId')?.value, jobId)
      .pipe(
        first(),
        finalize(() => {
          this.isSelectedJobApproverLoading.set(false);
        })
      ).subscribe({
        next: (jobLevels: JobLevel[]) => {
          this.setSelectedJobApprovers(jobLevels);
        },
        error: () => {
          this.snackBar.open(`Selected Job Approver: ${GENERIC_API_ERROR}`, SNACK_BAR_DISMISS_BUTTON, {
            panelClass: 'error',
            duration: SNACK_BAR_ERROR_DURATION,
          });
        }
      });
  }

  private setJobApprovers(jobLevels: JobLevel[]) {
    const approvers = get(jobLevels, '[0].jobEmployeesView', []);
    if (isEmpty(approvers)) {
      this.form.get('approverInfo')!.reset();
    }
    this.levelOneJobEmployee.set(approvers);
  }

  private setSelectedJobApprovers(jobLevels: JobLevel[]) {
    let allLevelsHaveActiveEmployee = true;
    for (const jobLevel of jobLevels) {
      if (!jobLevel.jobEmployeesView.some(employee => employee.employeeStatus === 'Active')) {
        allLevelsHaveActiveEmployee = false;
        break;
      }
    }
    if (!allLevelsHaveActiveEmployee) {
      this.snackBar.open('Job: This job lacks active approvers in all layers. Contact support to update before submitting the request.', SNACK_BAR_DISMISS_BUTTON, {
        panelClass: 'error',
        duration: SNACK_BAR_ERROR_DURATION,
      });
      this.setJobApprovers([]);
    } else {
      this.setJobApprovers(jobLevels);
    }
  }

  private setSelectedJob() {
    const selectedJob = this.form.get('jobInfo')!.value;
    if (!isEmpty(selectedJob.jobLevels)) {
      this.setSelectedJobApprovers(selectedJob.jobLevels);
    } else {
      const selectedJobId = get(selectedJob, 'jobId', 0);
      if (selectedJobId > 0) {
        this.getSelectedJobApprover(selectedJobId);
      }
    }
  }

  onJobChange(jobInfo: JobSummary) {
    this.form.get('jobInfo')!.setValue(jobInfo);
    this.setSelectedJob();
  }

  private continueCreateRequest = () => {
    if (this.form.valid) {
      this.createService.setRequestInformation(this.form.value);
      this.cancelButtonHandler();
      this.router.navigateByUrl(CREATE_REQUEST_URL);
    } else {
      this.form.markAllAsTouched();
    }
    this.jobShowError.set(this.form.invalid);
  }

  private cancelButtonHandler = () => {
    this.dialog.closeAll();
  }

  cancelButtonConfig: Button = {
    title: 'Cancel',
    action: this.cancelButtonHandler,
    analyticValue: 'Cancel Create Request',
    type: 'button',
    buttonColor: 'warn',
    icon: 'cancel'
  };

  continueButtonConfig: Button = {
    title: 'Continue',
    action: this.continueCreateRequest,
    showLoading: false,
    analyticValue: 'Continue to Create Request',
    buttonColor: 'primary',
    type: 'submit',
    icon: 'add'
  };
}
