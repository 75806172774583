<app-navbar></app-navbar>
<section class="app-container">
  <router-outlet></router-outlet>
</section>
<app-footer></app-footer>

<ng-template #appUpdater>
  <div mat-dialog-title class="dialog-header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <mat-icon>browser_updated</mat-icon>
    New version available!
  </div>
  <mat-dialog-content>
    <p>
      A new version of the application is available. Please refresh the page to get the latest updates.
    </p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <app-button [configuration]="[refreshButton]" (click)="refreshPage()">Refresh</app-button>
  </mat-dialog-actions>
</ng-template>
