import { Component, inject, input, OnDestroy, OnInit, signal } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { finalize, Subscription } from 'rxjs';
import { SNACK_BAR_DISMISS_BUTTON, SNACK_BAR_ERROR_DURATION } from 'src/app/GlobalConstants';
import { GENERIC_API_ERROR } from 'src/app/MessageConstants';
import { CompanyItem, CompanyResponseTime } from 'src/app/admin/company/company.model';
import { LoginService } from 'src/app/login/login.service';
import { MaterialModule } from 'src/app/material.module';
import { CompanyControlProperties } from '../../shared.model';
import { PrintErrorComponent } from '../print-error/print-error.component';
import { CompanyListService } from './company-list.service';

@Component({
  selector: 'app-company-list',
  templateUrl: './company-list.component.html',
  styleUrl: './company-list.component.scss',
  imports: [
    MaterialModule,
    PrintErrorComponent,
    ReactiveFormsModule
  ]
})
export class CompanyListComponent implements OnInit, OnDestroy {
  private companyService = inject(CompanyListService);
  private snackBar = inject(MatSnackBar);
  private loginService: LoginService = inject(LoginService);

  readonly controlInfo = input.required<CompanyControlProperties>();
  private subscribe$: Subscription = new Subscription();
  private readonly profileData = this.loginService.profileData.asReadonly();

  isCompanyLoading = signal<boolean>(false);
  companyList = signal<CompanyItem[]>([]);
  companyControl: FormControl = new FormControl();

  ngOnInit(): void {
    this.subscribeToCompany();
  }

  companyOnChange(event: MatSelectChange) {
    this.setCompanyId(event.value);
  }

  private setCompanyId(value: number) {
    if (value) {
      this.companyControl.setValue(value);
      this.controlInfo().controlName?.setValue(value);
      if (!this.controlInfo().defaultValue) {
        this.controlInfo().handler(value);
      }
    }
  }

  private subscribeToCompany() {
    this.isCompanyLoading.set(true);
    this.subscribe$.add(
      this.companyService.getCompanyList().pipe(
        finalize(() => {
          this.isCompanyLoading.set(false);
        })
      ).subscribe({
        next: (companyData: CompanyResponseTime) => {
          this.companyList.set(companyData.companyList);
          const companyValue: number = (this.controlInfo().defaultValue ?? this.profileData()?.companyId) as number;
          this.setCompanyId(companyValue);
        },
        error: () => {
          this.snackBar.open(`Company List: ${GENERIC_API_ERROR}`, SNACK_BAR_DISMISS_BUTTON, {
            panelClass: 'error',
            duration: SNACK_BAR_ERROR_DURATION,
          });
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscribe$.unsubscribe();
  }
}
