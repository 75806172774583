import { HttpClient } from '@angular/common/http';
import { effect, inject, Injectable, signal } from '@angular/core';
import { cloneDeep, get } from 'lodash';
import { BehaviorSubject, finalize } from 'rxjs';
import { API_SERVICE_URL } from 'src/app/GlobalConstants';
import { PageHeaderService } from 'src/app/shared/includes/page-header/page-header.service';
import { ItemReportService } from '../reports/item-report/item-report.service';
import { LogDetailsService } from '../request-details/log-details/log-details.service';
import { RequestDetailService } from '../request-details/request-detail.service';
import { AttachmentViewer } from '../shared/attachments/attachments.model';
import { AttachmentsService } from '../shared/attachments/attachments.service';
import { JobSummary } from '../shared/job-summary/job-summary.model';
import { JobSummaryService } from '../shared/job-summary/job-summary.service';
import { CreateFormRequest, CreateRequest, CreateRequestMaterial, StoreRequestFormItem } from "./create-request.model";

@Injectable({
  providedIn: 'root',
})
export class CreateRequestService {
  private http = inject(HttpClient);
  private itemService = inject(ItemReportService);
  private attachService = inject(AttachmentsService);
  private reqDetailService = inject(RequestDetailService);
  private logService = inject(LogDetailsService);
  private pageService = inject(PageHeaderService);
  private jobService = inject(JobSummaryService);

  public requestInformation = signal<CreateRequest>({});
  public editItemId = signal<number>(0);
  private serviceUrl = `${API_SERVICE_URL}/createrequest`;

  private readonly isLogoutTriggered = this.pageService.logoutTriggered.asReadonly();
  constructor() {
    effect(() => {
      if (this.isLogoutTriggered()) {
        this.resetCache();
      }
    });
  }

  public postRequest = (postData: CreateFormRequest) => {
    return this.http.post(this.serviceUrl, postData).pipe(
      finalize(() => {
        this.pageService.setApiReloadStatus({ myRequestSummary: true });
      }));
  }

  public getRequestMaterialList(): CreateRequestMaterial[] { return cloneDeep(get(this.requestInformation(), 'details', [])); }

  public setRequestInformation(param: CreateRequest, resetValue = false) {
    if (resetValue) {
      this.attachService.attachments$.next([]);
    }
    if (resetValue) {
      this.requestInformation.set(param);
    } else {
      this.requestInformation.update((currentValue) => {
        return {
          ...currentValue,
          ...param,
        };
      })
    }
    if (!param?.details && param?.approverInfo?.employeeId) {
      const jobFlow = this.reqDetailService.setInitialLogs({ approverId: param?.approverInfo?.employeeId }, param.jobInfo as JobSummary);
      this.logService.setJobFlow('_create', jobFlow);
    }
  }

  public setRequestStoreInformation(param: StoreRequestFormItem[]) {
    const currentValue: CreateRequestMaterial[] = this.requestInformation()?.details as CreateRequestMaterial[];
    const updatedValue = currentValue.map((item) => {
      param.forEach((storeRecords) => {
        if (item.id === storeRecords.itemId) {
          item.sivNumber = storeRecords.storeRecords.sivNumber;
          item.lpoNumber = storeRecords.storeRecords.lpoNumber;
          item.storeRemarks = storeRecords.remarks;
        }
      });
      return item;
    });
    this.setRequestInformation({ details: updatedValue });
  }

  public setEditItemId(value: number) {
    this.editItemId.set(value);
  }

  public getItemDescription(companyId: number) {
    return this.itemService.getDescription(companyId);
  }

  public getAttachments(): BehaviorSubject<AttachmentViewer[]> { return this.attachService.attachments$ }

  public setAttachments(param: AttachmentViewer[]) {
    this.attachService.attachments$.next(param);
  }

  public getJobLogs = (serialNumber: string) => this.logService.getJobFlow(serialNumber);

  public getSelectedJobApprover = (companyId: number, jobId: number) => this.jobService.getSelectedJobApprover(companyId, jobId);

  private resetCache() {
    this.requestInformation.set({});
    this.setEditItemId(0);
  }
}
