import { HttpClient } from '@angular/common/http';
import { Injectable, effect, inject } from '@angular/core';
import { get, set } from 'lodash';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { API_SERVICE_URL } from 'src/app/GlobalConstants';
import { PageHeaderService } from '../includes/page-header/page-header.service';
import { DepartmentParsedInfo, DepartmentUser, DepartmentUserRequest } from './department-user.model';

@Injectable({
  providedIn: 'root',
})

export class DepartmentUserService {
  private http = inject(HttpClient);
  private pageService = inject(PageHeaderService);

  private departmentUsersInfo: DepartmentParsedInfo = {};

  private readonly isLogoutTriggered = this.pageService.logoutTriggered.asReadonly();
  constructor() {
    effect(() => {
      if (this.isLogoutTriggered()) {
        this.resetCache();
      }
    });
  }

  getDepartmentUsers = (params: DepartmentUserRequest) => {
    const { companyId, departmentIds } = params;

    if (!companyId || departmentIds.length === 0) {
      return this.pageService.errorHelper('getDepartmentUsers: missing the required params.');
    }

    const reloadStatuses = ['department', 'employee', 'requestDetail'].some(
      status => get(this.pageService.apiReloadStatus$.value, status)
    );

    if (!reloadStatuses) {
      const filteredResult = departmentIds
        .map(department => this.departmentUsersInfo[department.id])
        .filter(Boolean);

      if (filteredResult.length === departmentIds.length) {
        return of(filteredResult);
      }
    }

    const departmentId = departmentIds.map(d => d.id).join(',');
    const departmentUrl = `${API_SERVICE_URL}/company/${companyId}/departmentinformation/${departmentId}/employee`;

    return this.http.get<DepartmentUser[]>(departmentUrl).pipe(
      tap((departmentUsers: DepartmentUser[]) => {
        departmentUsers.forEach((departmentUser: DepartmentUser) => {
          set(this.departmentUsersInfo, departmentUser.id, departmentUser);
        });
      })
    );
  }

  private resetCache() {
    this.departmentUsersInfo = {};
  }
}
