import { environment } from "src/environments/environment";

export const ADMIN_COMPANY_URL = '/admin/company';
const ADMIN_JOB_URL = `${ADMIN_COMPANY_URL}/%s/job`;
export const ADMIN_JOB_CHANGE_APPROVER = `${ADMIN_JOB_URL}/change-approver`;

export const APP_URL = environment.homePageUrl;
export const CHANGE_PAGE_URL = '/changes';
export const LOGIN_PAGE_URL = '/login';
export const CREATE_REQUEST_URL = '/client/my-request/create';
export const REQUEST_DETAIL_DIRECT_URL = '/client/search/%s';
export const MY_REQUEST_PAGE_URL = '/client/my-request';
export const MY_TASK_PAGE_URL = '/client/my-task';
export const JOB_REPORTS_PAGE_URL = '/client/reports/jobs';
export const MATERIAL_REPORTS_PAGE_URL = '/client/reports/materials';
export const REQUEST_PENDING_PAGE_URL = '/client/reports/pending-request';
export const ASSET_REPORTS_PAGE_URL = '/client/reports/assets';
export const ITEM_REPORTS_PAGE_URL = '/client/reports/items';
export const PROFILE_PAGE_URL = '/client/profile';
export const COMPANY_PAGE_URL = '/admin/company';
export const DEPARTMENT_PAGE_URL = '/admin/company/%s/department';
export const EMPLOYEE_PAGE_URL = '/admin/company/%s/employee';
export const JOB_PAGE_URL = '/admin/company/%s/job';
export const COST_CODE_PAGE_URL = '/admin/company/%s/cost-code';
export const UPDATE_REQUEST_URL = '/update';
export const METRICS_PAGE_URL = '/client/reports/metrics';
