import { ApplicationRef, inject, Injectable, signal } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
import { concat, first, interval } from "rxjs";

@Injectable({ providedIn: 'root' })
export class PwaUpdateService {
  private swUpdate = inject(SwUpdate);
  private appRef = inject(ApplicationRef);

  public isUpdateAvailable = signal<boolean>(false);

  public monitorUpdates() {
    if (!this.swUpdate.isEnabled) {
      return;
    }
    this.checkForManualUpdate();

    this.swUpdate.versionUpdates.subscribe(evt => {
      switch (evt.type) {
        case 'VERSION_DETECTED':
          console.log(`Downloading new app version: ${evt.version.hash}`);
          break;
        case 'VERSION_READY':
          console.log(`Current app version: ${evt.currentVersion.hash}`);
          console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
          this.isUpdateAvailable.set(true);
          break;
        case 'VERSION_INSTALLATION_FAILED':
          console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
          break;
      }
    });
  }

  checkForManualUpdate(intervalMinutes = 5) {
    const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
    const everyInterval$ = interval(intervalMinutes * 60 * 1000);
    const everyIntervalOnceAppIsStable$ = concat(appIsStable$, everyInterval$);

    everyIntervalOnceAppIsStable$.subscribe(() => {
      this.swUpdate.checkForUpdate().then(
        (hasNewVersionAvailable) => {
          if (hasNewVersionAvailable) {
            this.swUpdate.activateUpdate()
          }
        }
      )
    });
  }
}
