import { HttpClient } from '@angular/common/http';
import { Injectable, effect, inject } from '@angular/core';
import { get, isEmpty, set } from 'lodash';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { API_SERVICE_URL } from 'src/app/GlobalConstants';
import { PageHeaderService } from 'src/app/shared/includes/page-header/page-header.service';
import { JobDetailResponse, JobLevel, JobSummary, JobSummaryCompactResponse, JobSummaryRequest, JobSummaryResponse } from './job-summary.model';

@Injectable({
  providedIn: 'root'
})

export class JobSummaryService {
  private http = inject(HttpClient);
  private pageService = inject(PageHeaderService);

  private jobSummaryList: JobSummaryResponse = {};
  private jobDetailList: JobDetailResponse = {};

  private readonly isLogoutTriggered = this.pageService.logoutTriggered.asReadonly();
  constructor() {
    effect(() => {
      if (this.isLogoutTriggered()) {
        this.resetCache();
      }
    });
  }
  getJobDetail(param: JobSummaryRequest): Observable<JobSummary> {
    const {
      jobId,
      companyId,
    } = param;

    if (!companyId || !jobId) {
      return this.pageService.errorHelper('getJobDetail: missing the required params.');
    }

    const existingValue = get(this.jobDetailList, jobId);
    const reloadStatus = get(this.pageService.apiReloadStatus$.value, 'requestDetail');
    if (!reloadStatus && !isEmpty(existingValue)) {
      return of(existingValue);
    }

    const jobSummaryUrl = API_SERVICE_URL + `/company/${companyId}/jobsummary/${jobId}`;
    return this.http.get<JobSummary>(jobSummaryUrl)
      .pipe(
        tap((jobDetail: JobSummary) => {
          set(this.jobDetailList, jobId, jobDetail);
        })
      );
  }

  getJobSummary(companyId: number): Observable<JobSummary[]> {
    if (!companyId) {
      return this.pageService.errorHelper('getJobSummary: missing the required params.');
    }

    const existingValue = get(this.jobSummaryList, companyId);
    if (!isEmpty(existingValue)) {
      return of(existingValue);
    }

    const jobSummaryUrl = API_SERVICE_URL + `/company/${companyId}/jobsummary/compact`;
    return this.http.get<JobSummaryCompactResponse>(jobSummaryUrl)
      .pipe(
        map((jobResponse: JobSummaryCompactResponse) => {
          const jobList: JobSummary[] = jobResponse.values.map((job) => {
            var jobItem = {};
            jobResponse.keys.forEach((key, index) => {
              set(jobItem, key, job[index]);
            });
            return jobItem as JobSummary;
          });
          set(this.jobSummaryList, companyId, jobList);
          return jobList;
        })
      );
  }


  public getSelectedJobApprover(companyId: number, jobId: number) {
    return this.http.get<JobLevel[]>(`${API_SERVICE_URL}/company/${companyId}/jobsummary/${jobId}/approvers`)
      .pipe(
        tap((jobLevel: JobLevel[]) => {
          const jobIndex = this.jobSummaryList[companyId].findIndex((job) => job.jobId === jobId);
          set(this.jobSummaryList[companyId][jobIndex], 'jobLevels', jobLevel);
          return jobLevel;
        })
      );
  };

  private resetCache() {
    this.jobSummaryList = {};
    this.jobDetailList = {};
  }
}
