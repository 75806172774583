import { HttpClient } from '@angular/common/http';
import { Injectable, effect, inject } from '@angular/core';
import { get, isEmpty } from 'lodash';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { API_SERVICE_URL } from 'src/app/GlobalConstants';
import { AdminCompanyItem, CompanyResponseTime } from 'src/app/admin/company/company.model';
import { PageHeaderService } from '../page-header/page-header.service';

@Injectable({
  providedIn: 'root',
})

export class CompanyListService {
  private http = inject(HttpClient);
  private pageService = inject(PageHeaderService);

  private companyDataset: CompanyResponseTime = {} as CompanyResponseTime;
  private companyUrl = API_SERVICE_URL + '/companyinformation/summary';

  private readonly isLogoutTriggered = this.pageService.logoutTriggered.asReadonly();
  constructor() {
    effect(() => {
      if (this.isLogoutTriggered()) {
        this.resetCache();
      }
    });
  }

  getCompanyList(): Observable<CompanyResponseTime> {
    const reloadStatus = get(this.pageService.apiReloadStatus$.value, 'companyList');
    const existingValue = this.companyDataset;
    if (!reloadStatus && !isEmpty(existingValue)) {
      return of(existingValue);
    }

    return this.http.get<AdminCompanyItem[]>(this.companyUrl)
      .pipe(
        map((companyList: AdminCompanyItem[]) => {
          const revisedResponse = {
            requestAsOf: new Date(),
            companyList: companyList || [],
          }
          this.companyDataset = revisedResponse;
          this.pageService.apiReloadStatus$.next({ departmentList: false });
          return revisedResponse;
        })
      );
  }

  private resetCache() {
    this.companyDataset = {} as CompanyResponseTime;
  }
}
