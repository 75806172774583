import { HttpClient } from '@angular/common/http';
import { Injectable, effect, inject } from '@angular/core';
import { get, set } from 'lodash';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { API_SERVICE_URL } from 'src/app/GlobalConstants';
import { PageHeaderService } from 'src/app/shared/includes/page-header/page-header.service';
import { AttachmentParamRequest, AttachmentResponse, AttachmentViewer } from './attachments.model';

@Injectable({
  providedIn: 'root',
})
export class AttachmentsService {
  private http = inject(HttpClient);
  private pageService = inject(PageHeaderService);

  private serviceUrl = `${API_SERVICE_URL}/createrequest`;
  public attachments$: BehaviorSubject<AttachmentViewer[]> = new BehaviorSubject([] as AttachmentViewer[]);
  private attachmentLists: AttachmentResponse = {};

  private readonly isLogoutTriggered = this.pageService.logoutTriggered.asReadonly();
  constructor() {
    effect(() => {
      if (this.isLogoutTriggered()) {
        this.resetCache();
      }
    });
  }
  postUploadFile = (formData: FormData): Observable<AttachmentViewer[]> => {
    const fileUploadUrl = `${this.serviceUrl}/upload`;
    const defaultValue: AttachmentViewer = {
      employeeId: 0,
      employeeName: '',
      fileName: '',
      filePath: '',
      id: 0,
    };

    return this.http.post<AttachmentViewer[]>(fileUploadUrl, formData) //, getFileUploadHttpHeaders()
      .pipe(
        tap((files: AttachmentViewer[]) => {
          const updatedFiles = files.map((file) => ({
            ...defaultValue,
            ...file
          }));
          this.attachments$.next([
            ...this.attachments$.value,
            ...updatedFiles,
          ]);
        }),
      );
  }

  deleteUploadedFile = (param: AttachmentParamRequest) => {
    const fileDeleteUrl = `${this.serviceUrl}/deletefile`;

    return this.http.post<AttachmentViewer>(fileDeleteUrl, param)
      .pipe(
        tap((file: AttachmentViewer) => {
          this.attachments$.next([
            ...this.attachments$.value.filter((attachment: AttachmentViewer) => {
              return !(attachment.fileName === file.fileName && attachment.filePath === file.filePath);
            })
          ])
          this.pageService.apiReloadStatus$.next({ requestDetail: true });
        })
      );
  }

  saveAttachment = (requestId: number, postData: AttachmentParamRequest) => {
    const saveAttachmentUrl = `${this.serviceUrl}/${requestId}/saveattachment`;
    return this.http.post<AttachmentViewer[]>(saveAttachmentUrl, postData);
  }

  getAttachments = (requestId: number): Observable<AttachmentViewer[]> => {
    const existingValue = get(this.attachmentLists, requestId);
    const reloadStatus = get(this.pageService.apiReloadStatus$.value, 'requestDetail');
    if (!reloadStatus && existingValue) {
      this.attachments$.next(existingValue);
      return of(existingValue);
    }

    const attachmentUrl = `${API_SERVICE_URL}/attachment?reqId=${requestId}`;

    return this.http.get<AttachmentViewer[]>(attachmentUrl)
      .pipe(
        tap((attachments: AttachmentViewer[]) => {
          set(this.attachmentLists, requestId, attachments);
          this.attachments$.next(attachments);
        }),
      );
  }

  getFile(fileName: string): Observable<Blob> {
    const fileUrl = `${API_SERVICE_URL}/materialrequisition/file/${encodeURIComponent(fileName)}`;
    return this.http.get(fileUrl, {
      responseType: 'blob'
    });
  }

  private resetCache() {
    this.attachments$.next([]);
    this.attachmentLists = {};
  }
}
