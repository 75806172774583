<nav class="navbar-header" aria-label="Top Toolbar">
  <a [href]="homePageUrl" class="navbar-brand-link">
    <svg viewBox="2 0 85 38" class="navbar-brand" xmlns="http://www.w3.org/2000/svg">
      <text class="mat-headline-4" x="1.546" y="25">{{applicationTitle}}</text>
      <text class="description mat-headline-6" x="3.154" y="35">{{applicationDescription}}</text>
    </svg>
  </a>
  <div class="flex-spacer navbar-start"></div>
  @if(profileData()?.id) {
    <a mat-button [matMenuTriggerFor]="reportsMenu" *appAuth="['Admin', 'Manager']">
      <mat-icon>menu_book</mat-icon>
      <span class="hide-menu-label">Reports &rsaquo;</span>
    </a>
    @if(profileData()?.isApprover) {
      <a mat-button routerLinkActive="navbar-header-item-selected" [routerLink]="myTaskPageUrl">
        <mat-icon>task</mat-icon>
        <span class="hide-menu-label">My Tasks</span>
      </a>
    }
    <a mat-button routerLinkActive="navbar-header-item-selected" [routerLink]="myRequestPageUrl">
      <mat-icon>topic</mat-icon>
      <span class="hide-menu-label">Requests</span>
    </a>

    @if(!location.path().includes('admin') && !location.path().includes('update')) {
      <a mat-button (click)="showNewRequest()">
        <mat-icon>post_add</mat-icon>
        <span class="hide-menu-label">New Request</span>
      </a>
    }
    <div class="flex-spacer"></div>
    <input type="search" placeholder="Search" class="navbar-search" (keyup)="searchHandler($event)" aria-label="Search">
    <a mat-button [matMenuTriggerFor]="usersMenu" class="navbar-profile">
      <mat-icon>assignment_ind</mat-icon>
      <span class="last-menu-label text-overflow">{{profileData()?.name | titlecase}}</span>
    </a>
    <mat-menu #reportsMenu="matMenu">
      <a mat-menu-item [routerLink]="jobReportPageUrl">
        <mat-icon>folder</mat-icon>
        <span>Requests - Summary</span>
      </a>
      <a mat-menu-item [routerLink]="materialReportPageUrl">
        <mat-icon>description</mat-icon>
        <span>Requests - Itemized</span>
      </a>
      <a mat-menu-item [routerLink]="reportPendingPageUrl">
        <mat-icon>hourglass_top</mat-icon>
        <span>Requests - Pending</span>
      </a>
      <a mat-menu-item [routerLink]="assetReportPageUrl">
        <mat-icon>inventory</mat-icon>
        <span>Assets</span>
      </a>
      <a mat-menu-item [routerLink]="itemReportPageUrl">
        <mat-icon>design_services</mat-icon>
        <span>Items</span>
      </a>
      <a mat-menu-item [routerLink]="metricsPageUrl">
        <mat-icon>assessment</mat-icon>
        <span>Metrics</span>
      </a>
    </mat-menu>
    <mat-menu #usersMenu="matMenu">
      <button mat-menu-item disabled class="navbar-profile-name">
        <span class="mat-small">{{profileData()?.name | titlecase}}</span>
        <span class="mat-small text-overflow">{{profileData()?.email}}</span>
      </button>
      <mat-divider></mat-divider>
      @if(userData()?.isAdmin || userData()?.isSpecialRole) {
        <a mat-menu-item [routerLink]="companyPageUrl">
          <mat-icon>settings</mat-icon>
          <span>Configuration</span>
        </a>
      }
      <a mat-menu-item [routerLink]="profilePageUrl">
        <mat-icon>assignment_ind</mat-icon>
        <span>My Profile</span>
      </a>
      <a mat-menu-item (click)="initiateLogout($event)">
        <mat-icon>logout</mat-icon>
        <span>Logout</span>
      </a>
      <mat-divider></mat-divider>
      <a mat-menu-item [routerLink]="changePageUrl">
        <span class="mat-small">App Version: {{version}}</span>
      </a> 
    </mat-menu>
  } @else {
    <a mat-button routerLinkActive="navbar-header-item-selected" [routerLink]="loginPageUrl">
      <mat-icon>lock</mat-icon>
      <span>Login</span>
    </a>
  }
</nav>
