import { Routes } from "@angular/router";
import { APPLICATION_BROWSER_TITLE } from "../GlobalConstants";
import { LoginGuard } from "../login/login.guard";
import { stringMerge } from "../shared/utilities/helper";

const requestDetailRoutes: Routes = [
  {
    path: '',
    loadComponent: () => import('./request-details/request-detail.component').then(m => m.RequestDetailsComponent),
    title: stringMerge(APPLICATION_BROWSER_TITLE, 'Request Details'),
  },
  {
    path: 'update',
    loadComponent: () => import('./create-request/update-request/update-request.component').then(c => c.UpdateRequestComponent),
    title: stringMerge(APPLICATION_BROWSER_TITLE, 'Update Request'),
  },
];

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'my-request',
    pathMatch: 'full'
  },
  {
    path: 'my-task',
    canActivate: [LoginGuard],
    children: [
      {
        path: '',
        loadComponent: () => import('./my-task/my-task.component').then(m => m.MyTaskComponent),
        data: { customRoles: ['Approver'] },
        title: stringMerge(APPLICATION_BROWSER_TITLE, 'View Assigned Tasks'),
      },
      {
        path: ':requestNumber',
        children: requestDetailRoutes
      },
    ],
  },
  {
    path: 'my-request',
    children: [
      {
        path: '',
        loadComponent: () => import('./my-request/my-request.component').then(m => m.MyRequestComponent),
        title: stringMerge(APPLICATION_BROWSER_TITLE, 'View My Requests'),
      },
      {
        path: 'create',
        loadComponent: () => import('./create-request/create-request.component').then(c => c.CreateRequestComponent),
        title: stringMerge(APPLICATION_BROWSER_TITLE, 'Create Request'),
      },
      {
        path: ':requestNumber',
        children: requestDetailRoutes
      },
    ]
  },
  {
    path: 'search',
    children: [
      {
        path: '',
        redirectTo: '/client/my-request',
        pathMatch: 'full',
      },
      {
        path: ':requestNumber',
        children: requestDetailRoutes
      },
    ]
  },
  {
    path: 'profile',
    loadComponent: () => import('./profile/profile.component').then(m => m.ProfileComponent),
    title: stringMerge(APPLICATION_BROWSER_TITLE, 'Profile'),
  },
  {
    path: 'reports',
    children: [
      {
        path: 'assets',
        children: [
          {
            path: '',
            loadComponent: () => import('./reports/asset-report/asset-report.component').then(m => m.AssetReportComponent),
            title: stringMerge(APPLICATION_BROWSER_TITLE, 'Assets Report'),
          },
          {
            path: ':requestNumber',
            children: requestDetailRoutes
          },
        ],
      },
      {
        path: 'materials',
        children: [
          {
            path: '',
            loadComponent: () => import('./reports/job-material/job-material.component').then(m => m.JobMaterialComponent),
            title: stringMerge(APPLICATION_BROWSER_TITLE, 'Materials Report'),
          },
          {
            path: ':requestNumber',
            children: requestDetailRoutes
          },
        ]
      },
      {
        path: 'pending-request',
        children: [
          {
            path: '',
            loadComponent: () => import('./reports/request-pending/request-pending.component').then(m => m.RequestPendingComponent),
            title: stringMerge(APPLICATION_BROWSER_TITLE, 'Request Pending Report'),
          },
          {
            path: ':requestNumber',
            children: requestDetailRoutes
          },
        ]
      },
      {
        path: 'jobs',
        children: [
          {
            path: '',
            loadComponent: () => import('./reports/job-request/job-request.component').then(m => m.JobRequestComponent),
            title: stringMerge(APPLICATION_BROWSER_TITLE, 'Jobs Report'),
          },
          {
            path: ':requestNumber',
            children: requestDetailRoutes
          },
        ],
      },
      {
        path: 'items',
        loadComponent: () => import('./reports/item-report/item-report.component').then(m => m.ItemReportComponent),
        title: stringMerge(APPLICATION_BROWSER_TITLE, 'Items Report'),
      },
      {
        path: 'metrics',
        loadComponent: () => import('./reports/metrics-report/metrics-report.component').then(m => m.MetricsReportComponent),
        title: stringMerge(APPLICATION_BROWSER_TITLE, 'Metrics Report'),
      }
    ],
  },
];
